import * as signalR from "@microsoft/signalr";
import { SignalRNegotiate } from "../../shared/models/shared/signalR/signalRNegotiate.model";
import { SignalRService } from "../api/signalR/signalRService";
import { SignalRMessageWithTarget } from "../../shared/models/shared/signalR/signalRMessageWithTarget.model";
import { SignalRTargets } from "../../shared/models/shared/signalR/enum/signalRTargets";

const TOKEN_REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes

class Connector {
  private connection!: signalR.HubConnection;
  private onMessageReceived!: (message: SignalRMessageWithTarget) => void;
  static instance: Connector;
  private accessToken: string = "";
  private url: string = "";

  private constructor(url: string, accessToken: string) {
    this.url = url;
    this.accessToken = accessToken;

    if (!this.url || !this.accessToken) {
      console.error("URL and Access Token must be provided.");
    } else {
      this.initializeConnection();
      this.initialize();
    }
  }

  private initializeConnection() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.url, {
        accessTokenFactory: () => this.accessToken,
      })
      .withAutomaticReconnect()
      .build();
  }

  private async fetchToken() {
    try {
      const response: SignalRNegotiate = await getUrlAndToken();

      this.url = response.URL;
      this.accessToken = response.AccessToken;

      if (
        this.connection &&
        this.connection.state === signalR.HubConnectionState.Connected
      ) {
        await this.connection.stop();
        this.initializeConnection();
        await this.connection.start();
        this.attachEventHandlers();
      } else if (this.connection) {
        this.initializeConnection();
        await this.connection.start();
        this.attachEventHandlers();
      }
    } catch (err) {
      console.error("Error fetching token:", err);
    }
  }

  private async startConnection() {
    if (
      this.connection != null &&
      this.connection.state === signalR.HubConnectionState.Disconnected
    ) {
      try {
        await this.connection.start();
      } catch (err) {
        console.error("SignalR Connection Error: ", err);
        setTimeout(() => this.startConnection(), 5000); // Retry connection after 5 seconds
      }
    }
  }

  private attachEventHandlers() {
    try {
      if (this.onMessageReceived) {
        const targets = Object.values(SignalRTargets);
        targets.forEach((target) => {
          this.connection.on(target, (message: any) => {
            const messageWithTarget: SignalRMessageWithTarget = {
              Target: target,
              Message: message,
            };
            this.onMessageReceived(messageWithTarget);
          });
        });
      }
    } catch (err) {
      console.error("Error attaching event handlers to SignalR" + err);
    }
  }

  private async initialize() {
    await this.startConnection();
    setInterval(() => this.fetchToken(), TOKEN_REFRESH_INTERVAL); // 30 minutes
  }

  public static async getInstance(): Promise<Connector> {
    if (!Connector.instance) {
      const signalRNegotiate = await getUrlAndToken();
      Connector.instance = new Connector(
        signalRNegotiate.URL,
        signalRNegotiate.AccessToken
      );
      await Connector.instance.initialize();
    }
    return Connector.instance;
  }

  public setEventHandlers(
    onMessageReceived: (message: SignalRMessageWithTarget) => void
  ) {
    this.onMessageReceived = onMessageReceived;
    this.attachEventHandlers();
  }
}

async function getUrlAndToken(): Promise<SignalRNegotiate> {
  const signalRService = new SignalRService();
  let response: SignalRNegotiate = await signalRService.getUrlAndAccessToken();

  return response;
}

export default Connector;
