import {
  Box,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import {
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridColumnMenuProps,
  GridColumnMenuContainer
} from "@mui/x-data-grid-pro";
import React, {
  SetStateAction,
  useState,
  useEffect
} from "react";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../../styles/theme";
import { VesselGroupingService } from "../../../../../service/api/vessel/vesselGroupingService";
import { VesselGrouping } from "../../../../models/vessel/vesselGrouping.model";
import { VesselGroupingModel } from "../../../../models/vessel/modelVesselGrouping.model";
import VesselGroupingTypeFilter from "./filters/vesselGroupingTypeFilter.component";
import VesselGroupingCategoryFilter from "./filters/vesselGroupingCategoryFilter.component";
import { useGlobalProgressBarService } from "../../../../context/globalProgressBar/globalProgressBar.Context";
import { useToastMessageService } from "../../../../context/toast/toastMessage.Context";
import { ClientUtility } from "../../../../../utils/data/client.utility";
import { CustomerUtility } from "../../../../../utils/data/customer.utility";

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlinedIcon />;
}

const getGridColumns = (
  t: Function,
  editClickHandler: Function): GridColDef[] => {
  return [
    {
      field: "type",
      headerName: "TYPE",
      flex: 1
    }, {
      field: "order",
      headerName: "ORDER",
      flex: 1,
      disableColumnMenu: true
    }, {
      field: "category",
      headerName: "CATEGORY",
      flex: 1,
    }, {
      field: "name",
      headerName: "NAME",
      flex: 1,
      disableColumnMenu: true
    }, {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
      sortable: false,
      disableColumnMenu: true
    }, {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        if (row.type === "Industry") {
          return [
            <GridActionsCellItem
              sx={{ cursor: "auto" }}
              icon={<div style={{ width: "20px" }} />}
              label={""}
            />,
            <GridActionsCellItem
              icon={<Tooltip disableInteractive={row.description.length === 0} title={row.description}>
                <span>
                  <InfoIcon color={row.description.length === 0 ? "disabled" : "inherit"} />
                </span>
              </Tooltip>}
              label={t("component.action.delete")}
              color="inherit"
            />
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label={t("component.action.edit")}
              className="textPrimary"
              onClick={() => { editClickHandler(id); }}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<Tooltip disableInteractive={row.description.length === 0} title={row.description}>
                <span>
                  <InfoIcon color={row.description.length === 0 ? "disabled" : "inherit"} />
                </span>
              </Tooltip>}
              label={t("component.action.delete")}
              color="inherit"
            />
          ];
        }
      }
    }
  ]
};

const StyledDataGrid = styled(DataGridPro)(() => ({

  "& .MuiDataGrid-menuIcon": {
    visibility: "visible", 
    width: "auto",
  },

  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiDataGrid-cell": {
    border: "transparent",
  },
  "& .MuiDataGrid-container--top [role=row]": {
    backgroundColor: theme.background.component,
    fontSize: "14px",
  },
  "& .MuiDataGrid-withBorderColor": {
    border: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-container--top::after": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-overlayWrapper": {
    height: "30px",
  }
  
}));

interface ComponentProperties {
  initDataReload: boolean;
  onDataReloaded: (isDataReloaded: boolean) => void;
  onEditClickHandler: (vesselGroupingModel: VesselGroupingModel) => void;
  setGroupingCategories: (groupingCategories: Map<string, number>) => void;
}

export const DataGridVesselGrouping = (props: ComponentProperties) => {
  const vesselGroupingService = new VesselGroupingService();
  const [data, setData] = useState<VesselGrouping[]>([]);
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState<VesselGrouping[]>([]);
  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();
  const { addToast } = useToastMessageService();
  const { t } = useTranslation();

  const selectedClientId: string = ClientUtility.GetSelectedClientId() ?? "";
  const selectedCustomerId: string = CustomerUtility.GetSelectedCustomerId() ?? "";

  const [groupingTypes, setGroupingTypes] = useState<string[]>([]);
  const [selectedGroupingTypes, setSelectedGroupingTypes] = useState<string[]>([]);
  const [groupingCategories, setGroupingCategories] = useState<string[]>([]);
  const [selectedGroupingCategories, setSelectedGroupingCategories] = useState<string[]>([]);

  const editClickHandler = (vesselGroupingId: string) => {
    if (vesselGroupingId.length > 0) {
      const vesselGrouping: VesselGrouping | undefined = filteredData.find((row) => row.id === vesselGroupingId);
      if (vesselGrouping !== undefined) {
        const vesselGroupingModel: VesselGroupingModel = {
          id: vesselGroupingId,
          groupingName: vesselGrouping.name,
          groupingCategoryName: vesselGrouping.category,
          groupingType: vesselGrouping.type,
          groupingOrder: Number(vesselGrouping.order),
          groupingDescription: vesselGrouping.description
        } as VesselGroupingModel;
        props.onEditClickHandler(vesselGroupingModel);
      } else {
        addToast("error", `Vessel grouping ${vesselGroupingId} not found.`);
      }
    }
  };

  const initFiltersAndPrepareCategories = (vesselGroupingItems: VesselGrouping[]): void => {
    const allTypes: string[] = [];
    const allCategories: string[] = [];
    const organizationCategories: Map<string, number> = new Map<string, number>();
    for (const groupingItem of vesselGroupingItems) {
      if (groupingItem.type && groupingItem.type.length > 0) {
        allTypes.push(groupingItem.type);
      }
      if (groupingItem.category &&
        groupingItem.category.length > 0) {
        allCategories.push(groupingItem.category);

        if (groupingItem.type.toLowerCase() === "organization") {
          const categoryOrder = groupingItem.order ?? 0;
          const categoryName = groupingItem.category;
          if (organizationCategories.has(categoryName)) {
            const order = organizationCategories.get(categoryName) ?? 0;
            if (categoryOrder > order) {
              organizationCategories.set(categoryName, categoryOrder);
            }
          } else {
            organizationCategories.set(categoryName, categoryOrder);
          }
        }
      }
    }
    setGroupingTypes(Array.from(new Set(allTypes)));
    setGroupingCategories(Array.from(new Set(allCategories)));
    props.setGroupingCategories(organizationCategories);
  }

  const fetchData = () => {
    addProgressItem();
    vesselGroupingService.getGroupingItemsAsync(selectedCustomerId, selectedClientId)
      .then((vesselGroupingItems) => {
        initFiltersAndPrepareCategories(vesselGroupingItems);
        setData(vesselGroupingItems);
      })
      .catch(() => {
        setGroupingTypes([]);
        setGroupingCategories([]);
        setData([]);
        addToast("error", "Vessel grouping catalog error");
      })
      .finally(() => {
        removeProgressItem();
        props.onDataReloaded(true);
      });
  };

  const getFilteredData = () => {
    let filteredData = data;
    if (selectedGroupingTypes.length > 0) {
      filteredData = data.filter((row) => selectedGroupingTypes.some((type) => type.toLowerCase() === row.type.toLowerCase()));
    }

    if (selectedGroupingCategories.length > 0) {
      filteredData = filteredData.filter((row) => selectedGroupingCategories.some((category) => category.toLowerCase() === (row.category ?? "").toLowerCase()));
    }

    return filteredData.filter(
      (row) =>
        row.type.toLowerCase().includes(filter.toLowerCase()) ||
        row.order?.toString().includes(filter.toString()) ||
        row.category?.toLowerCase().includes(filter.toLowerCase()) ||
        row.name?.toLowerCase().includes(filter.toLowerCase()) ||
        row.description?.toLowerCase().includes(filter.toLowerCase())
    );
  }

  const handleFilterChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setFilter(event.target.value);
  };

  const CustomColumnMenu = (props: GridColumnMenuProps) => {
    const { hideMenu, colDef } = props;
    return <GridColumnMenuContainer open={false} colDef={colDef} hideMenu={hideMenu}>
      {colDef.field === "type" ?
        (<VesselGroupingTypeFilter
          vesselGroupingTypes={groupingTypes}
          selectedVesselGroupingTypes={selectedGroupingTypes}
          setSelectedVesselGroupingTypes={setSelectedGroupingTypes} />) :
        colDef.field === "category" ?
          (<VesselGroupingCategoryFilter
            vesselGroupingCategories={groupingCategories}
            selectedVesselGroupingCategories={selectedGroupingCategories}
            setSelectedVesselGroupingCategories={setSelectedGroupingCategories} />) : null}
    </GridColumnMenuContainer>
  }

  useEffect(() => {
    setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  useEffect(() => {
    if (selectedClientId && selectedCustomerId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerId]);

  useEffect(() => {
    if (props.initDataReload) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initDataReload]);

  useEffect(() => {
    const filteredData = getFilteredData();
    setFilteredData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter]);

  useEffect(() => {
    const filteredData = getFilteredData();
    setFilteredData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupingTypes]);

  useEffect(() => {
    const filteredData = getFilteredData();
    setFilteredData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupingCategories]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: theme.background.component,
        borderRadius: "8px",
        marginTop: "10px",
      }}
    >
      <Box>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            verticalAlign: "middle",
            justifyContent: "space-evenly",
            padding: "5px",
          }}
        >
          <Box>
            <Typography
              sx={{
                background: theme.background.componentLight,
                borderRadius: "8px",
                fontSize: "20px",
                height: "30px",
                textAlign: "left",
                paddingLeft: "5px",
                paddingRight: "5px"
              }}
            >
              Count: {filteredData.length}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              sx={{ width: "300px", float: "right" }}
              onChange={handleFilterChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
      </Box>
      <Box>
        <StyledDataGrid
          sx={{
            border: "none",
            BorderStyle: "none !important"            
          }}
          getRowId={(row) => row.id}
          rows={filteredData}
          columns={getGridColumns(t, editClickHandler)}
          disableRowSelectionOnClick
          hideFooter
          disableDensitySelector
          autoHeight
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
            columnMenu: CustomColumnMenu,
            columnMenuIcon: FilterListIcon
          }} />
      </Box>
    </Box>
  );
};

export default DataGridVesselGrouping;