import React, {
    useState,
    useEffect
} from "react";
import { 
  Box,
  Button,
  Modal,
  Stack,
  Tooltip
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../../styles/theme";
import VesselGroupingDetails from "./vesselGroupingDetails.component";
import { ClientUtility } from "../../../../../utils/data/client.utility";
import { CustomerUtility } from "../../../../../utils/data/customer.utility";
import { VesselGroupingModel } from "../../../../models/vessel/modelVesselGrouping.model";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '60%',
  maxWidth: '90%',
  backgroundColor: theme.background.component,
  borderColor: theme.background.component,
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "6px",
  display: "inline-block",
  padding: "6px",
  maxHeight: "95%",
  overflowY: "auto",
  overflowX: "auto",
};

interface ComponentProperties {
    onGroupingCreatedHandler: (value: boolean) => void;
    editVesselGrouping: VesselGroupingModel | null;
    open: boolean;
    vesselGroupingCategories: Map<string, number>;
    onClose: () => void;
}

export const VesselGroupingDetailsModal = (props: ComponentProperties) => {
    const { t } = useTranslation();

    const selectedClientId: string = ClientUtility.GetSelectedClientId() ?? "";
    const selectedCustomerId: string = CustomerUtility.GetSelectedCustomerId() ?? "";

    const [open, setOpen] = useState(false);
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [cancelButtonClicked, setCancelButtonClicked] = useState(false);

    const dataSavedHandler = (savedResult: boolean) => {
        setSaveButtonClicked(false);
        if (savedResult) {
            props.onGroupingCreatedHandler(savedResult);
            props.onClose();
        }
        setOpen(!savedResult)
    };

    const cancelHandler = (canBeClosed: boolean) => {
        setCancelButtonClicked(false);
        if (canBeClosed) {
            setSaveButtonClicked(false);
            setCancelButtonClicked(false);
            setOpen(false);
            props.onClose();
        }
    }

    useEffect(() => {
        if (!props.open) {
          setSaveButtonClicked(false);
          setCancelButtonClicked(false);
        }

        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        if (props.editVesselGrouping) {
            setOpen(true);
        }
    }, [props.editVesselGrouping]);

    return (
      <Modal
        open={open}
        onClose={() => setCancelButtonClicked(true) }
      >
        <Box sx={modalStyle}>
            <Box sx={{
                width: "100%",
                display: "inline-block",
                backgroundColor: theme.background.componentLight,
                borderColor: theme.background.componentLight,
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "6px",
                padding: "15px 6px 6px 6px"
            }}>
                <Box sx={{ width: "100%" }}>
                    <VesselGroupingDetails
                        saveButtonClicked={saveButtonClicked}
                        cancelButtonClicked={cancelButtonClicked}
                        clientId={selectedClientId}
                        customerId={selectedCustomerId}
                        onSavedHandler={dataSavedHandler}
                        onCancelHandler={cancelHandler}
                        groupingCategories={props.vesselGroupingCategories}
                        setRequestInProgress={setRequestInProgress}
                        editVesselGrouping={props.editVesselGrouping}
                    />
                </Box>
            </Box>
            <Stack
                alignItems="center"
                justifyContent="flex-end"
                direction="row"
                spacing={1}
                sx={{
                    marginTop: "6px",
                }}
            >
                <Button
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.colors.blueGray,
                    }}
                    id="cancelVesselGroupingDetailsButton"
                    onClick={() => setCancelButtonClicked(true)}
                >
                    {t("component.action.cancel")}
                </Button>
                <Tooltip title={requestInProgress ? t("service.api.another_request_in_progress") : ""}>
                    <span>
                        <Button
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.secondary.contrastText,
                            }}
                            id="addVesselGroupingDetailsButton"
                            onClick={() => setSaveButtonClicked(true)}
                            disabled={requestInProgress}
                        >
                            {t("component.action.save")}
                        </Button>
                    </span>
                </Tooltip>
            </Stack>
        </Box>
      </Modal>
  );
};

export default VesselGroupingDetailsModal;