import {
  Box,
  Divider,
  Stack, 
  Typography 
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PrimaryButton} from "../../styled/button/styledButtonPrimary.component";
import React from "react";
import {useSelectedClientApp} from "../../../context/clientApplication/clientApplicationSelection.context";
import {useSelectedCustomer} from "../../../context/customer/customerSelection.Context";
import {useToastMessageService} from "../../../context/toast/toastMessage.Context";
import HoverMenuButton from "../../styled/button/hoverMenuButton.component";
import {FileDownload} from "@mui/icons-material";
import {CustomPortService} from "../../../../service/api/port/portService";

interface ComponentProperties {
  onAddPortButtonClickedHandler: () => void;
}

export const PortHeader = (props: ComponentProperties) => {
  const customPortService = new CustomPortService();
  const {t} = useTranslation();
  const exportOptions: string[] = [t("component.port.export_csv"), t("component.port.export_json")];  
  const {addToast} = useToastMessageService();
  const addButtonClickHandler = () => {
    if(!selectedClientId) {
      addToast("error", t("error.no_client_selected"));
      return;
    }
    if(!selectedCustomerId) {
      addToast("error", t("error.no_customer_selected"));
      return;
    }

    props.onAddPortButtonClickedHandler();
  };
  let selectedClientId = useSelectedClientApp().selectedClientApp?.clientApp?.id ?? "";
  let selectedCustomerId = useSelectedCustomer().selectedCustomer?.id ?? "";

  const handleExportOptionClick = (option: string, index: number) => {    

    if(index === exportOptions.indexOf(t("component.port.export_csv"))){
        customPortService.exportCustomPortsExcelAsync(selectedCustomerId).then(() => {
        addToast("info", `Exported`);
      }, (error) => {
        addToast("error", `${option} Failed to export: ${error}`);
      });
      return;
    }

    if(index === exportOptions.indexOf(t("component.port.export_json"))){
      customPortService.exportCustomPortsJsonAsync(selectedCustomerId).then(() => {
        addToast("info", `Exported`);
      }, (error) => {
        addToast("error", `${option} Failed to export: ${error}`);
      });
      return;
    }
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h2" component="h2">{ t("component.port.header") }</Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
          spacing={1}
        >
          
          <PrimaryButton
              id="addCustomerPortButton"
              onClick={addButtonClickHandler}
          >
            {t("component.port.action.add_port")}
          </PrimaryButton>

          <HoverMenuButton
            label={t("component.port.export")}
            icon={<FileDownload />}
            options={exportOptions}
            onClick={handleExportOptionClick} />
        </Stack>
      </Stack>
      <Divider textAlign="right" sx={{ mb: 2 }}></Divider>
    </Box>
  );
};

export default PortHeader;