import {
  Box,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { 
  DataGridPro, 
  GridColDef,
  GridActionsCellItem
} from "@mui/x-data-grid-pro";
import React, {
  SetStateAction, 
  useState, 
  useEffect 
} from "react";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import { useSelectedClientApp } from "../../../context/clientApplication/clientApplicationSelection.context";
import { useSelectedCustomer } from "../../../context/customer/customerSelection.Context";
import { CustomPortService } from "../../../../service/api/port/portService";
import { Port } from "../../../models/port/port.model";
import { CustomPort } from "../../../models/port/protoPort.model";
import { useGlobalProgressBarService } from "../../../context/globalProgressBar/globalProgressBar.Context";
import { useToastMessageService } from "../../../context/toast/toastMessage.Context";
import { OkCancelModal } from "../../modal/okCancelModal.component";
import { ButtonTypes } from "../../../models/shared/button/buttonTypes";
import i18n from 'i18next';

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlinedIcon />;
}

const getGridColumns = (
    t: Function,
    editClickHandler: Function,
    deleteClickHandler: Function):GridColDef[] => {
  return [
    {
      field: "unLocode",
      headerName: t("component.port.grid.column.unLocode"),
      flex: 1,
    }, {
      field: "code",
      headerName: t("component.port.grid.column.code"),
      flex: 1,
    }, {
      field: "country",
      headerName: t("component.port.grid.column.country"),
      flex: 1,
    }, {
      field: "name",
      headerName: t("component.port.grid.column.name"),
      flex: 1,
    }, {
      field: "alias",
      headerName: t("component.port.grid.column.alias"),
      flex: 1,
    }, {
      field: "lastChangedDate",
      headerName: t("component.port.grid.column.lastChangedDate"),
      flex: 1,
    }, {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t("component.action.edit")}
            className="textPrimary"
            onClick={() => {editClickHandler(id);}}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("component.action.delete")}
            onClick={() => {deleteClickHandler(id);}}
            color="inherit"
          />,
        ];
      }
    }
  ]
};

const StyledDataGrid = styled(DataGridPro)(() => ({
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiDataGrid-cell": {
    border: "transparent",
  },
  "& .MuiDataGrid-container--top [role=row]": {
    backgroundColor: theme.background.component,
    fontSize: "14px",
  },
  "& .MuiDataGrid-withBorderColor": {
    border: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-container--top::after": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-overlayWrapper": {
    height: "30px",
  },
}));

interface ComponentProperties {
  initDataReload: boolean;
  onDataReloaded: (isDataReloaded: boolean) => void;
  onEditClickHandler: (customPort: CustomPort) => void;
}

export const DataGridPort = (props: ComponentProperties) => {
  const customPortService = new CustomPortService();
  const [data, setData] = useState<Port[]>([]);
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState<Port[]>([]);
  const [isDeletingPort, setIsDeletingPort] = useState(false);
  const [deletePortConfirmationMessage, setDeletePortConfirmationMessage] = useState("");
  const [customPortCodeToDelete, setCustomPortCodeToDelete] = useState("");
  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();
  const { addToast } = useToastMessageService();
  const { t } = useTranslation();

  const { selectedClientApp } = useSelectedClientApp();
  const { selectedCustomer } = useSelectedCustomer();
  
  const selectedClientId = selectedClientApp?.clientApp?.id ?? "";
  const selectedCustomerId = selectedCustomer?.id ?? "";

  const editClickHandler = (portCode: string) => {
    if (portCode.length > 0) {
      customPortService.getCustomPortAsync(selectedCustomerId, selectedClientId, portCode)
          .then((customPort) => {
            if (customPort != null) {
              props.onEditClickHandler(customPort);
            } else {
              addToast("error", `CustomPort ${portCode} not found`)
            }
          });
    }
  };

  const deletePortConfirmationHandler = (buttonType: string) => {
    setIsDeletingPort(false);

    if (buttonType === ButtonTypes.Yes) {
      if(customPortCodeToDelete.length > 0) {
        customPortService.deleteCustomPortAsync(selectedCustomerId, selectedClientId, customPortCodeToDelete)
          .then((deleteResult) => {
            if (deleteResult) {
              setFilteredData(filteredData.filter((row) => row.code !== customPortCodeToDelete));
            } else {
              addToast("error", `Delete CustomPort ${customPortCodeToDelete} request misses parameters.`)
            }
            setCustomPortCodeToDelete("");
          });
      }
    }
  };

  const deleteClickHandler = (portCode: string) => {
    if (portCode.length > 0) {
      setIsDeletingPort(true);
      setCustomPortCodeToDelete(portCode);
      setDeletePortConfirmationMessage(`${t("component.port.message.confirm_port_delete")} ${portCode}.`);
    }
  };

  const fetchData = () => {
      addProgressItem();
      customPortService.getCustomPortsAsync(selectedCustomerId, selectedClientId)
          .then((customPorts) => {
            setData(customPorts);
          })
          .catch(() => {
            setData([]);
            addToast("error", t("component.port.error.get_ports"));
          })
          .finally(() => {
            removeProgressItem();
            props.onDataReloaded(true);
          });
  };

  useEffect(() => {
    setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp]);

  useEffect(() => {
    if (selectedClientApp && selectedCustomer) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  useEffect(() => {
    if (props.initDataReload) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initDataReload]);

  const handleFilterChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    const newFilteredData = data.filter(
      (row) =>
        row.name.toLowerCase().includes(filter.toLowerCase()) ||
        row.code.toLowerCase().includes(filter.toLowerCase()) ||
        row.alias.toLowerCase().includes(filter.toLowerCase()) ||
        row.lastChangedDate
          .toString()
          .toLowerCase()
          .includes(filter.toLowerCase())
    );

    setFilteredData(newFilteredData);
  }, [data, filter]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: theme.background.component,
        borderRadius: "8px",
        marginTop: "10px",
      }}
    >
      <Box>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            verticalAlign: "middle",
            justifyContent: "space-evenly",
            padding: "5px",
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography
              sx={{
                background: theme.background.componentLight,
                borderRadius: "8px",
                fontSize: "20px",
                width: "100px",
                height: "30px",
                textAlign: "left",
                paddingLeft: "5px",
              }}
            >
              Count: {filteredData.length}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              sx={{ width: "300px", float: "right" }}
              onChange={handleFilterChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
      </Box>
      <Box>
        <StyledDataGrid
          sx={{
            border: "none",
            BorderStyle: "none !important",
            rowBorderColor: "red",
          }}
          getRowId={(row) => row.code}
          rows={filteredData}
          columns={getGridColumns(t, editClickHandler, deleteClickHandler)}
          disableRowSelectionOnClick
          hideFooter
          disableColumnMenu
          disableDensitySelector
          autoHeight
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
          }}
        />
      </Box>
      <OkCancelModal
        isOpen={isDeletingPort}
        onButtonClick={deletePortConfirmationHandler}
        title={i18n.t("routes.general.warning")}
        message={deletePortConfirmationMessage}
      />
    </Box>
  );
};

export default DataGridPort;