import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import {theme} from "../../../../styles/theme";

interface IHoverMenuButtonProps {
    label: string,
    icon?: React.ReactNode,
    options: string[],
    onClick: (option: string, index: number) => void;
}

const HoverMenuButton: React.FC<IHoverMenuButtonProps> = ({
    label,
    icon,
    options,
    onClick
}
) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = (option: string, index: number) => {
        onClick(option, index);
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                sx={{
                    borderRadius: "8px",
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.primary.main,
                    fontSize: "16px",
                    height: "40px",
                    padding: "1px 25px 1px 25px",
                    textTransform: "none",
                    width: "auto",
                    "&.Mui-disabled": {
                        color: "white"
                    }
                }}
                variant="contained"
                onMouseEnter={handleMouseEnter}
                aria-controls="hover-menu"
                aria-haspopup="true"
                startIcon={icon}
            >
                {label}
            </Button>

            <Menu
                id="hover-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMouseLeave}
                MenuListProps={{
                    onMouseLeave: handleMouseLeave,
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleOptionClick(option, index)}>
                        {option}
                    </MenuItem>))}
            </Menu>
        </div>
    );
};

export default HoverMenuButton;