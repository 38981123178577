import { Vessel, VesselCapitalized } from "../../shared/models/shared/vessel/vessel.model";

function vesselToLowerCaseModel(vessel: VesselCapitalized): Vessel | null {
  if (vessel == null) {
    return null;
  }

  return {
    imo: vessel.Imo,
    type: { value: vessel.VesselType },
    name: vessel.Name,
    deadweight: vessel.Deadweight,
    createdDateTime: vessel.CreatedDateTime,
    updatedDateTime: vessel.UpdatedDateTime,
  };
}

export { vesselToLowerCaseModel };