import {
    Autocomplete,
    Box,
    Divider,
    MenuItem,
    Select,
    styled,
    Typography
} from "@mui/material";
import React, {
    useEffect,
    useState
} from "react";
import i18n from 'i18next';
import {
    FieldPath,
    FormProvider,
    useForm,
    UseFormGetValues
} from "react-hook-form";
import { theme } from "../../../../../styles/theme";
import { StyledTextField } from "../../../styled/textfield/styledTextField.component";
import { VesselGroupingModel, VesselGroupingType } from "../../../../models/vessel/modelVesselGrouping.model"
import { VesselGrouping, VesselGroupingTypes } from "../../../../models/vessel/vesselGrouping.model"
import { VesselGroupingService } from "../../../../../service/api/vessel/vesselGroupingService";
import { useToastMessageService } from "../../../../context/toast/toastMessage.Context";
import { useGlobalProgressBarService } from "../../../../context/globalProgressBar/globalProgressBar.Context";

const textColor = theme.palette.text.primary;
const backgroundColor = "#141D27";
const borderColor = "#E8ECED";

const restrictedCharacters = /[!@#$%^&*]/;
const bySisterClassCategory = "By Sister Class";

const validateRestrictedCharacters = (input: string | null) => {
    return (!restrictedCharacters.test(input ?? "") || `The characters ${restrictedCharacters.toString().slice(2, -2)} are not allowed`);
}

const CustomStyledTextField = styled(StyledTextField)({
    marginTop: "0px",
    marginBottom: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiInputBase-root": {
        marginTop: "0px"
    },
    "& .MuiOutlinedInput-root": {
        paddingTop: "0px",
        paddingBottom: "0px"
    }
});

const SelectCustom = styled(Select)({
    backgroundColor: backgroundColor,
    "& .MuiInputLabel-root": {
        color: textColor,
        WebkitTextFillColor: textColor,
        transform: "translate(14px, -20px) scale(1)"
    },
    "& .MuiOutlinedInput-root": {
        WebkitTextFillColor: textColor,
        color: textColor,
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: borderColor
    },
    "& .MuiInputBase-input": {
        paddingTop: "9px",
        paddingBottom: "9px"
    }
});

const CustomAutocomplete = styled(Autocomplete)({
    "& .MuiInputBase-root": {
        marginTop: "0px"
    },
    "& .MuiFormControl-root": {
        marginTop: "0px"
    },
    "& .MuiOutlinedInput-root": {
        paddingTop: "1px",
        paddingBottom: "2px"
    }
})

export type FormFields = {
    groupingType: VesselGroupingType;
    groupingOrder: number;
    groupingCategory: string;
    groupingName: string | null;
    groupingDescription: string | null;
};

const allowedNumberOfOrganizationCategories: number = 3;

const getFormData = (vesselGrouping: VesselGroupingModel | null): FormFields => {
    if (vesselGrouping) {
        return getVesselGroupingFormData(vesselGrouping);
    } else {
        return getDefaultFormData();
    }
}

const getDefaultFormData = (): FormFields => {

    let formFields: FormFields;

    formFields = {
        groupingType: "Organization",
        groupingOrder: 1,
        groupingCategory: "",
        groupingName: "",
        groupingDescription: ""
    } as FormFields;

    return formFields;
};

const getVesselGroupingFormData = (vesselGrouping: VesselGroupingModel): FormFields => {

    let formFields: FormFields;

    formFields = {
        groupingType: vesselGrouping.groupingType,
        groupingOrder: vesselGrouping.groupingOrder,
        groupingCategory: vesselGrouping.groupingCategoryName,
        groupingName: vesselGrouping.groupingName,
        groupingDescription: vesselGrouping.groupingDescription
    } as FormFields;

    return formFields;
};

const createGrouping = async (
    clientId: string,
    customerId: string,
    getFormValues: UseFormGetValues<FormFields>,
    vesselGroupingService: VesselGroupingService): Promise<boolean> => {
    const formFields = getFormValues();

    const vesselGrouping: VesselGroupingModel = {
        groupingType: formFields.groupingType,
        groupingOrder: Number(formFields.groupingOrder),
        groupingName: formFields.groupingName ?? undefined,
        groupingDescription: formFields.groupingDescription ?? undefined,
        groupingCategoryName: formFields.groupingCategory
    };

    return await vesselGroupingService.postGroupingAsync(customerId, clientId, [vesselGrouping])
}

const updateGrouping = async (
    clientId: string,
    customerId: string,
    vesselGroupingId: string,
    getFormValues: UseFormGetValues<FormFields>,
    vesselGroupingService: VesselGroupingService): Promise<boolean> => {
    const formFields = getFormValues();

    const vesselGrouping: VesselGroupingModel = {
        id: vesselGroupingId,
        groupingType: formFields.groupingType,
        groupingOrder: Number(formFields.groupingOrder),
        groupingName: formFields.groupingName ?? undefined,
        groupingDescription: formFields.groupingDescription ?? undefined,
        groupingCategoryName: formFields.groupingCategory
    };

    return await vesselGroupingService.patchGroupingAsync(customerId, clientId, vesselGrouping)
}

const getCategories = (organizationCategories: Map<string, number>): string[] => {
    const categories = Array.from(organizationCategories.keys()).sort();

    if (categories.some(item => item.toLowerCase() === bySisterClassCategory.toLowerCase())) {
        return categories;
    }

    return [bySisterClassCategory, ...categories.filter(item => item.toLowerCase() !== bySisterClassCategory.toLowerCase())]
}

interface ComponentProperties {
    saveButtonClicked: boolean;
    onSavedHandler: (value: boolean) => void;
    clientId: string;
    customerId: string;
    onCancelHandler: (value: boolean) => void;
    cancelButtonClicked: boolean;
    groupingCategories: Map<string, number>;
    setRequestInProgress: (value: boolean) => void;
    editVesselGrouping: VesselGroupingModel | null;
}

export const VesselGroupingDetails = (props: ComponentProperties) => {
    const vesselGroupingService = new VesselGroupingService();
    const { addToast } = useToastMessageService();
    const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

    const requiredValidationRule = { value: true, message: i18n.t("error.field_is_required") };
    const maxLengthValidationRule = (maxLength: number) => { return { value: maxLength, message: i18n.t("error.field_max_length") }; };

    const [formValues, setFormValues] = useState<FormFields>(getFormData(props.editVesselGrouping));

    const formMethods = useForm<FormFields>({ defaultValues: formValues });
    const { register, reset, formState: { errors }, getValues, setValue, setError, trigger } = formMethods;

    const onCategoryBlurHandler = (event: any) => {
        const selectedCategory = event.target.value;

        setValue("groupingCategory", selectedCategory);

        trigger("groupingCategory")
            .then((isValid) => {
                if (isValid) {
                    if (selectedCategory.toLowerCase() === bySisterClassCategory.toLowerCase()) {
                        setValue("groupingOrder", 1);
                    } else {
                        setValue("groupingOrder", getNextCategoryOrder(selectedCategory));
                    }
                }
            })
    }

    const getNextCategoryOrder = (categoryName: string): number => {
        const organizationCategories = props.groupingCategories;

        if(!organizationCategories.has(bySisterClassCategory)) {
            organizationCategories.set(bySisterClassCategory, 1);
        }

        if (organizationCategories.has(categoryName)) {
            return organizationCategories.get(categoryName) ?? 1;
        } else {

            if (!canCreateOrganizationCategory(categoryName)) {
                const errorMessage = i18n.t(
                    "component.vessel_grouping_catalog.error.maximum_allowed_organization_categories",
                    { amount: allowedNumberOfOrganizationCategories});
                setError("groupingCategory", { type: "manual", message:  errorMessage});
            }

            const categoryOrders = Array.from(organizationCategories.values()).sort();
            for (let counter = 1; counter <= categoryOrders.length; counter++) {
                if (categoryOrders[counter - 1] !== counter) {
                    return counter;
                }
            }

            return categoryOrders[categoryOrders.length - 1] + 1;
        }
    }

    const getVesselGroupingAsync = async (
        vesselGroupingType: string,
        vesselGroupingCategory: string,
        vesselGroupingName: string): Promise<VesselGrouping | null> => {

        const gropings = await vesselGroupingService.getGroupingItemsAsync(props.customerId, props.clientId);

        const searchResult: VesselGrouping[] = gropings.filter(
            (item) => item.type.toLowerCase() === vesselGroupingType.toLowerCase() &&
                item.category?.toLowerCase() === vesselGroupingCategory.toLowerCase() &&
                item.name?.toLowerCase() === vesselGroupingName.toLowerCase());

        return searchResult.length > 0 ? searchResult[0] : null;
    }

    const onBlurHandler = (event: any, fieldName: FieldPath<FormFields>) => {
        trigger(fieldName)
            .then((isValid) => {
                if (isValid) {
                    setValue(fieldName, event.target.value);
                }
            })
    };

    const isGroupingNameExistsAsync = async (groupingType: string, groupingCategory: string, groupingName: string): Promise<boolean> => {
        const vesselGrouping = await getVesselGroupingAsync(groupingType, groupingCategory, groupingName);

        if (vesselGrouping != null) {
            if (props.editVesselGrouping) {
                if(props.editVesselGrouping.id !== vesselGrouping.id) {
                    return true;
                }
            } else {
                return true;
            }
        }

        return false;
    }

    const canCreateOrganizationCategory = (groupingCategory: string) => {

        if (props.groupingCategories.has(groupingCategory)) {
            return true;
        }

        return props.groupingCategories.size < allowedNumberOfOrganizationCategories;
    }

    const saveVesselGrouping = () => {
        trigger().then(async (isValid) => {
            if (isValid) {
                const saveGrouping = async () => {
                    const type = getValues().groupingType;
                    const category = getValues().groupingCategory;
                    const name = getValues().groupingName ?? "";

                    if (!canCreateOrganizationCategory(category)) {
                        const errorMessage = i18n.t(
                            "component.vessel_grouping_catalog.error.maximum_allowed_organization_categories",
                            { amount: allowedNumberOfOrganizationCategories});
                        setError("groupingCategory", { type: "manual", message:  errorMessage});
                        return false;
                    }

                    if (await isGroupingNameExistsAsync(type, category, name)) {
                        const errorMessage = i18n.t("routes.vessels.vessels_grouping_catalog_route.the_vessel_grouping_already_exists", { type: type, category: category, name: name });
                        setError("groupingName", { type: "manual", message: errorMessage });
                        return false;
                    }

                    if(props.editVesselGrouping){
                        const saveResult = await updateGrouping(
                            props.clientId,
                            props.customerId,
                            props.editVesselGrouping.id ?? "",
                            getValues,
                            vesselGroupingService);
                        if (saveResult) {
                            clearForm();
                            props.onSavedHandler(true);
                        } else {
                            addToast("error", i18n.t("component.vessel_grouping_catalog.error.update"));
                            props.onSavedHandler(false);
                        }

                        return saveResult;
                    } else {
                        const saveResult = await createGrouping(props.clientId, props.customerId, getValues, vesselGroupingService);
                        if (saveResult) {
                            clearForm();
                            props.onSavedHandler(true);
                        } else {
                            addToast("error", i18n.t("component.vessel_grouping_catalog.error.create"));
                            props.onSavedHandler(false);
                        }

                        return saveResult;
                    }
                };

                try {
                    props.setRequestInProgress(true);
                    addProgressItem();
                    const saveResult = await saveGrouping();
                    if (saveResult) {
                        addToast("success", i18n.t("service.api.request_has_been_submited_successfully"));
                    } else {
                        props.onSavedHandler(false);
                    }
                } catch (error: any) {
                    addToast("error", i18n.t("service.api.request_failed") + ":\n" + error.message);
                } finally {
                    props.setRequestInProgress(false);
                    removeProgressItem();
                }
            } else {
                props.onSavedHandler(false);
            }
        })
    };

    const setFormFieldsValues = (formValues: FormFields) => {
        setFormValues({ ...formValues });
    };

    const clearForm = () => {
        let formValues = getDefaultFormData();

        setFormFieldsValues(formValues);

        reset(formValues, { keepDirtyValues: true });
    };

    useEffect(() => {
        if (props.saveButtonClicked) {
            saveVesselGrouping();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saveButtonClicked]);

    useEffect(() => {
        if (props.cancelButtonClicked) {
            clearForm();
            props.onCancelHandler(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cancelButtonClicked]);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={(e) => e.preventDefault()} style={{ width: "auto" }}>
                <Typography
                    variant="h2"
                    component="h2"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    {props.editVesselGrouping ?
                        i18n.t("routes.vessels.vessels_grouping_catalog_route.update_vessel_grouping") :
                        i18n.t("routes.vessels.vessels_grouping_catalog_route.create_new_vessel_grouping")}
                </Typography>
                <Divider textAlign="right" sx={{ mb: 2 }}></Divider>

                {/* Classification */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingTop: "10px",
                    }}
                >
                    <span
                        style={{
                            flexBasis: "110px", 
                            flexShrink: 0,
                            textAlign: "right",
                            paddingRight: "8px", 
                        }}
                    >
                        {i18n.t("routes.vessels.vessels_grouping_catalog_route.classification")}
                    </span>
                    <SelectCustom
                        {...register("groupingType", {
                            required: requiredValidationRule,
                        })}
                        value={formValues.groupingType}
                        sx={{
                            width: "250px",
                            marginLeft: "5px",
                        }}
                        error={Boolean(errors.groupingType)}
                    >
                        {VesselGroupingTypes.map((groupingType, index) => (
                            <MenuItem value={groupingType} key={`vessel-grouping-type-${index}`}>
                                {groupingType}
                            </MenuItem>
                        ))}
                    </SelectCustom>
                </Box>

                {/* Container for all fields */}
                <Box
                    sx={{
                        width: "100%",
                        backgroundColor: theme.background.component,
                        border: `1px solid ${theme.background.component}`,
                        borderRadius: "6px",
                        padding: "15px 6px",
                        marginTop: "15px",
                    }}
                >
                    {/* Category and Order */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <span
                            style={{
                                flexBasis: "110px",
                                flexShrink: 0,
                                textAlign: "left",
                                paddingRight: "8px",
                            }}
                        >
                            {i18n.t("routes.vessels.vessels_grouping_catalog_route.category")}
                        </span>
                        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 2 }}>
                            <CustomAutocomplete
                                freeSolo
                                {...register("groupingCategory", {
                                    required: requiredValidationRule,
                                    maxLength: maxLengthValidationRule(50),
                                    validate: validateRestrictedCharacters,
                                })}
                                value={formValues.groupingCategory}
                                options={getCategories(props.groupingCategories)}
                                onBlur={onCategoryBlurHandler}
                                renderInput={(params) => (
                                    <CustomStyledTextField
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            maxLength: 50,
                                        }}
                                        error={Boolean(errors.groupingCategory)}
                                        helperText={errors.groupingCategory && errors.groupingCategory.message}
                                    />
                                )}
                                sx={{ width: "100%" }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "20%",
                            }}
                        >
                            <span
                                style={{
                                    flexBasis: "50px",
                                    flexShrink: 0,
                                    textAlign: "right",
                                    paddingRight: "8px",
                                }}
                            >
                                Order
                            </span>
                            <CustomStyledTextField
                                sx={{ width: "50px" }}
                                {...register("groupingOrder")}
                                disabled
                            />
                        </Box>
                    </Box>

                    {/* Description */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <span
                            style={{
                                flexBasis: "110px",
                                flexShrink: 0,
                                textAlign: "left",
                                paddingRight: "8px",
                            }}
                        >
                            {i18n.t("routes.vessels.vessels_grouping_catalog_route.description")}
                        </span>
                        <CustomStyledTextField
                            sx={{ flex: 1 }}
                            {...register("groupingDescription", {
                                maxLength: maxLengthValidationRule(400),
                                pattern: {
                                    value: /^[a-zA-Z0-9 -]*$/,
                                    message: i18n.t(
                                        "routes.vessels.vessels_grouping_catalog_route.only_alphanumeric_characters_are_allowed"
                                    ),
                                },
                            })}
                            onBlur={(e: any) => onBlurHandler(e, "groupingDescription")}
                            maxlength={400}
                            error={Boolean(errors.groupingDescription)}
                            helperText={errors.groupingDescription && errors.groupingDescription.message}
                        />
                    </Box>

                    {/* Name */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "10px",
                        }}
                    >
                        <span
                            style={{
                                flexBasis: "110px",
                                flexShrink: 0,
                                textAlign: "left",
                                paddingRight: "8px",
                            }}
                        >
                            {i18n.t("routes.vessels.vessels_grouping_catalog_route.name")}
                        </span>
                        <CustomStyledTextField
                            sx={{ flex: 1 }}
                            {...register("groupingName", {
                                required: requiredValidationRule,
                                maxLength: maxLengthValidationRule(50),
                                validate: validateRestrictedCharacters,
                            })}
                            onBlur={(e: any) => onBlurHandler(e, "groupingName")}
                            maxlength={50}
                            error={Boolean(errors.groupingName)}
                            helperText={errors.groupingName && errors.groupingName.message}
                        />
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};

export default VesselGroupingDetails;
