import { VesselGrouping } from "../../../shared/models/vessel/vesselGrouping.model"
import { VesselGroupingModel } from "../../../shared/models/vessel/modelVesselGrouping.model"
import { BaseService } from "../BaseService";
import { headerKey, path } from "./vesselBaseService.constants";
import { headerKey as generalHeaderKey } from "../common.constants";
import { SessionUtility } from "../../../utils/data/session.utility";
import { UserUtility } from "../../../utils/data/user.utility";
import { appConfig } from "../../../config/configLoader";

export class VesselGroupingService extends BaseService {

  public async getGroupingItemsAsync(
    tenantId: string,
    clientId: string | null
  ): Promise<VesselGrouping[]> {
    let filteredGroupingItems: VesselGrouping[] = [];

    if(!clientId || !tenantId) {
      return filteredGroupingItems;
    }

    let pageNumber = 0;
    const pageSize = 100;
    let getNextPage = true;
    do {
      const responseData = await this.get<VesselGroupingModel[]>(
          `${path.VESSEL_GROUPING_CATALOG}?page_num=${pageNumber}&page_size=${pageSize}`,
          {
            headers: {
              [headerKey.CC_ACTIVE_CLIENT]: appConfig.MSAL_CONFIG_CLIENT_ID,
              [headerKey.CC_TENANT_ID]: tenantId,
              [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
              [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
            }
          }
      );

      const vesselGroupingItems = responseData?.data;

      if (getNextPage) {
        vesselGroupingItems?.forEach((item) => {
          const vesselGrouping: VesselGrouping = {
            id: item.id,
            type: item.groupingType,
            name: item.groupingName,
            description: item.groupingDescription,
            category: item.groupingCategoryName,
            order: item.groupingOrder
          } as VesselGrouping;
          filteredGroupingItems.push(vesselGrouping);
        });

        if (vesselGroupingItems?.length < pageSize) {
          getNextPage = false;
        } else {
          pageNumber++;
        }
      }
    } while(getNextPage);

    return filteredGroupingItems;
  }

  public async postGroupingAsync(
      tenantId: string,
      clientId: string | null,
      vesselGroupings: Array<VesselGroupingModel>
  ): Promise<boolean> {

    if (!clientId || !tenantId) {
      return false;
    }

    try {
      await this.post<VesselGroupingModel[]>(
          path.VESSEL_GROUPING_CATALOG,
          vesselGroupings,
          {
            headers: {
              [headerKey.CC_ACTIVE_CLIENT]: appConfig.MSAL_CONFIG_CLIENT_ID,
              [headerKey.CC_TENANT_ID]: tenantId,
              [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
              [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
            }
          }
      );

      return true;
    } catch(error) {
      return false;
    }
  }

  public async patchGroupingAsync(
      tenantId: string,
      clientId: string | null,
      vesselGrouping: VesselGroupingModel
  ): Promise<boolean> {

    if (!clientId || !tenantId) {
      return false;
    }

    try {
      await this.patch<VesselGroupingModel[]>(
          `${path.VESSEL_GROUPING_CATALOG}`,
          vesselGrouping,
          {
            headers: {
              [headerKey.CC_ACTIVE_CLIENT]: appConfig.MSAL_CONFIG_CLIENT_ID,
              [headerKey.CC_TENANT_ID]: tenantId,
              [headerKey.CC_VESSEL_GROUPING_ID]: vesselGrouping.id,
              [generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
              [generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId()
            }
          }
      );

      return true;
    } catch(error) {
      return false;
    }
  }
}