import { Box, Button, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { theme } from "../../../styles/theme";
import { useSelectedClientApp } from "../../context/clientApplication/clientApplicationSelection.context";
import { ISelectedClientApplication } from "../../context/clientApplication/selectedClientApplication.context.model";
import { useEffect, useState } from "react";
import { Client } from "../../models/client/client.model";
import { ClientService } from "../../../service/api/client/clientService";
import { useToastMessageService } from "../../context/toast/toastMessage.Context";
import { useGlobalProgressBarService } from "../../context/globalProgressBar/globalProgressBar.Context";
import { useNavigate } from "react-router-dom";
import { ClientApplicationType } from "../../type/clientApplication.type";
import { clientConstants } from "../../../constants/global.constants";
import { Sync } from "@mui/icons-material";
import { OkCancelModal } from "../modal/okCancelModal.component";
import { ButtonTypes } from "../../models/shared/button/buttonTypes";
import i18n from 'i18next';
import { ClientUtility } from "../../../utils/data/client.utility";
import { SyncService } from "../../../service/api/sync/syncService";

export const ClientApplicationSelect = () => {
  const [clientAppItems, setClientAppItems] = useState<ClientApplicationType[]>([]);

  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

  const { addToast } = useToastMessageService();
  const { selectClientApp, selectedClientApp, loading } = useSelectedClientApp();
  const [selectValue, setSelectValue] = useState<string>("");
  const clientService = new ClientService();
  const navigate = useNavigate();
  const [syncWithMDF, setSyncWithMDF] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const allowedClientTags: Array<string> = [clientConstants.MDF_KEYWORD];

  const isMDFClient = selectedClientApp?.clientApp?.tags?.includes(clientConstants.MDF_KEYWORD);

  const startMDFSync = () => {
    if (isLoading) return;
    setIsLoading(true);
    setSyncWithMDF(true);
  };

  const syncMDF = (buttonType: string) => {
    setSyncWithMDF(false);
    try {
      if (buttonType === ButtonTypes.Yes) {
        callSyncService();
      }

    }
    finally {
      setIsLoading(false);
    }
  };

  const handleClientAppSelect = (appId: string) => {
    const data: ISelectedClientApplication = {
      clientApp:
        clientAppItems.find((app) => app.id === appId) ?? clientAppItems[0],
    };

    if (data != null && data.clientApp != null) {
      selectClientApp(data);
    } else {
      addToast("error", "Client not found");
    }
  };

  const syncService = new SyncService();

  const callSyncService = async () => {
    try {
      const selectedClientId = ClientUtility.GetSelectedClientId();

      const { status } = await syncService.getSyncClientService(selectedClientId);

      if (status === 200) {
        addToast("success", i18n.t("service.sync.success_request_submitted"));

      }
      else {
        addToast("error", i18n.t("service.sync.error_calling_the_function_to_synchronize_client_data"));
      }
    }
    catch (error) {
      addToast("error", i18n.t("service.sync.error_calling_the_function_to_synchronize_client_data"));
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        addProgressItem();

        const clients: Client[] = (await clientService.getClientsService())
          .data as Client[];

        if (clients?.length > 0) {
          const newClientAppItems = clients
            .filter((client) => allowedClientTags.includes(client.Tags))
            .map((client) => ({
              id: client.ClientId,
              name: client.ClientName,
              description: client.ClientName,
              tags: client.Tags,
            })) as ClientApplicationType[];

          setClientAppItems(newClientAppItems);

          if (selectedClientApp?.clientApp?.id == null) {
            const firstClientApp = newClientAppItems[0];
            setSelectValue(firstClientApp.id);
            selectClientApp({ clientApp: firstClientApp });
          }
        }

        return clients;
      } catch (error) {
        addToast("error", "Error fetching client applications");
      } finally {
        removeProgressItem();
      }
    };

    const fetchDataAsync = async () => {
      await fetchData();
    };

    if (!loading) {
      fetchDataAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (
      selectedClientApp?.clientApp?.id &&
      clientAppItems.length > 0 &&
      clientAppItems.find((app) => app.id === selectedClientApp?.clientApp?.id)
    ) {
      navigate("/");
      setSelectValue(selectedClientApp?.clientApp?.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp, clientAppItems]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="2"
      sx={{ width: "100%" }}>
      <Select
        name="ClientApplicationSelect"
        autoWidth={true}
        value={selectValue}
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText,
          height: "46px",
          width: "100%",
        }}
        onChange={(e) => handleClientAppSelect(e.target.value as string)}
      >
        {clientAppItems.map((app) => (
          <MenuItem value={app.id} key={app.id} data-id="ClientAppItem">
            {app.description}
          </MenuItem>
        ))}
      </Select>
      <Box sx={{ width: "46px", display: "flex", justifyContent: "center" }}>
        {isMDFClient && (
          <Tooltip title={i18n.t("component.customer.sync_with") + `${selectedClientApp?.clientApp?.name}`} arrow>
            <Button
              variant="text"
              color="primary"
              startIcon={<Sync />}
              disabled={isLoading}
              onClick={startMDFSync}>
            </Button>
          </Tooltip>
        )}
      </Box>

      <OkCancelModal
        isOpen={syncWithMDF}
        onButtonClick={syncMDF}
        title={i18n.t("routes.general.warning")}
        message={i18n.t("component.customer.sync_warn_message")}
      />
    </Stack>
  );
};