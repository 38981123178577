export const path = {
  VESSEL_PERSIST_RAW_VESSEL_FILE:
    "/onboardingmiddleware-vesselprocessing/PersistRawVesselFileAdapter",
  VESSEL_GROUPING_CATALOG: "/onboardingmiddleware/vessel-grouping",
  VESSEL_PROCESSING_STATE: "/onboardingmiddleware-vesselprocessing/VesselProcessingStateFunction",
  PERSIST_RAW_VESSEL_FILE_ADAPTER: "/onboardingmiddleware-vesselprocessing/PersistRawVesselFileAdapter",
  COMMUNICATION_ADAPTER: "/onboardingmiddleware-vesselprocessing/CommunicationAdapter"
};

export const headerKey = {
  CC_ACTIVE_CLIENT: "cc-active-client",
  CC_CLIENTTENANT_ID: "cc-clienttenantid",
  CC_TENANT_ID: "cc-tenantid",
  CC_SKIP_CLIENT_ID_VALIDATION: "cc-skipclientidvalidation",
  CC_VESSEL_GROUPING_ID: "cc-groupingid"
};

export const paging = {
  MAX_PAGE_SIZE: 100,
};
